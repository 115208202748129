import React, { useState} from 'react';
import "./Login.css";

export function LoginComponent({appMode, setAppMode, cookie, cookieSetter, cookieRemovingFunction}) {
    const [username, setUsername] = useState('username')
	const [password, setPassword] = useState('password')
    const [email, setEmail] = useState('email')
    const [passwordExists, setPasswordExists] = useState(false);
    
    let LoginRegisterUI = <></>
    const request_path = 'https://dannysapps.site:5000'
    
    function handleInputChange({event, setterFunction, setPasswordExists, initialValue}) {
        if (initialValue === 'password') {
            setPasswordExists(true)
        }
        setterFunction(event.target.value)
    }

    function setEmptyValue({event, setterFunction, initialValue}) {
        if (event.target.value === initialValue) {
            setterFunction('')
        }
    }

    function setPreviousValue({event, setterFunction, setPasswordExists, initialValue, previousValue}) {
        if (previousValue.trim() === '') {
            if (initialValue === 'password') {
                setterFunction(initialValue)
                setPasswordExists(false)
            } else {
                setterFunction(initialValue)
            }
        } else {
            setterFunction(previousValue);
        }
    }

    if (appMode === 'logging in' || appMode === 'Error logging in' || appMode === 'logged out') {
        LoginRegisterUI = 
        <div className = "login">
            {appMode === 'logged out' && <h5>Successfully logged out</h5>}
            <h4>Login</h4>
            <br/>
            <form autoComplete="off">
                <div className="text_area">
                    <input
                    type="text"
                    id="username"
                    name="username"
                    className="text_input"
                    value = {username} 
                    onChange={(event) => handleInputChange({event, setterFunction:setUsername, setPasswordExists: setPasswordExists, initialValue: 'username'})} 
                    onFocus={(event) => setEmptyValue({event, setterFunction: setUsername, initialValue: 'username'})}
                    onBlur={(event) => setPreviousValue({event, setterFunction: setUsername, setPasswordExists: setPasswordExists, initialValue: 'username', previousValue: username})}
                    />
                </div>
                <div className="text_area">
                    <input
                    type={passwordExists ? "password" : "text"}
                    id="password"
                    name="password"
                    className="text_input"
                    value = {password} 
                    onChange={(event) => handleInputChange({event, setterFunction:setPassword, setPasswordExists: setPasswordExists, initialValue: 'password'})} 
                    onFocus={(event) => setEmptyValue({event, setterFunction: setPassword, initialValue: 'password'})}
                    onBlur={(event) => setPreviousValue({event, setterFunction: setPassword, setPasswordExists: setPasswordExists, initialValue: 'password', previousValue: password})}
                    />
                </div>
                <button className="btn-hover color-3" onClick={(event) => handleLoginClick({event})}>Log in</button>
                <br/>
                <button className="small-btn-hover color-3" onClick = {(event) => handleRegisterClick({event})}>New here? Create an account</button>
                {appMode === "Error logging in" && <label style = {{color: 'red'}}>Error logging in</label>}
            </form>
        </div>
    }

    if (appMode === 'registering new user' || appMode === 'Error registering new user' ) {
        LoginRegisterUI = 
        <div className = "register">
            <h4>Create an account</h4>
            <br/>
            <form autoComplete="off">
                <div className="text_area">
                    <input
                    type="text"
                    id="username"
                    name="username"
                    className="text_input"
                    value = {username} 
                    onChange={(event) => handleInputChange({event, setterFunction:setUsername, setPasswordExists: setPasswordExists, initialValue: 'username'})} 
                    onFocus={(event) => setEmptyValue({event, setterFunction: setUsername, initialValue: 'username'})}
                    onBlur={(event) => setPreviousValue({event, setterFunction: setUsername, setPasswordExists: setPasswordExists, initialValue: 'username', previousValue: username})}
                    />
                </div>

                <div className="text_area">
                    <input
                    type="text"
                    id="email"
                    name="email"
                    className="text_input"
                    value = {email} 
                    onChange={(event) => handleInputChange({event, setterFunction:setEmail, setPasswordExists: setPasswordExists, initialValue: 'email'})} 
                    onFocus={(event) => setEmptyValue({event, setterFunction: setEmail, initialValue: 'email'})}
                    onBlur={(event) => setPreviousValue({event, setterFunction: setEmail, setPasswordExists: setPasswordExists, initialValue: 'email', previousValue: email})}
                    />
                </div>

                <div className="text_area">
                    <input
                    type={passwordExists ? "password" : "text"}
                    id="password"
                    name="password"
                    className="text_input"
                    value = {password} 
                    onChange={(event) => handleInputChange({event, setterFunction:setPassword, setPasswordExists: setPasswordExists, initialValue: 'password'})} 
                    onFocus={(event) => setEmptyValue({event, setterFunction: setPassword, initialValue: 'password'})}
                    onBlur={(event) => setPreviousValue({event, setterFunction: setPassword, setPasswordExists: setPasswordExists, initialValue: 'password', previousValue: password})}
                    />
                </div>
                <button className="btn-hover color-3" onClick={(event) => handleCreateAccount({event})}>Create account</button>
                <button className="btn-hover color-3" onClick={(event) => handleBackToLogin({event})}>Back to login</button>
                {appMode === 'Error registering new user' && <label style = {{color: "red"}}>Error creating account</label>}
            </form>
            </div>
    }

    if (appMode === 'redirecting to login') {
        LoginRegisterUI = 
        <div className = "redirect_to_login">
            <br/>
            <br/>
            <h5>Account created successfully</h5>
            <br/>
            <form autoComplete="off">
                <button className="btn-hover color-3" onClick={(event) => handleLoginClickFromRegister({event})}>Log in</button>
            </form>
        </div>
    }


    const handleLoginClick = async ({event}) => {
        event.preventDefault()
        console.log("Clicked on log in")

        try {
            const request_options = {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Headers": "*", // this will allow all CORS requests
                    "Access-Control-Allow-Methods": 'OPTIONS,POST,GET', // this states the allowed methods
                    "Content-Type": "application/json" // this shows the expected content type
                },
                body: JSON.stringify({'username': username, 'password': password})
            }
            const response =  await fetch(request_path + '/authenticate_username_and_password', request_options)
            const response_data =  await response.json()
            console.log('Got back: ', response_data)

            if (!response.ok || response_data['result'] !== 'ok') {
                throw Error("Error logging in")
            }

            console.log("Logged in, searching for cookies now")
            console.log("Cookie: ", cookie.user)

            if (!cookie.user) {
                console.log("Setting the cookie ", response_data['token'])
                cookieSetter({user: response_data['token']})
            }

            setAppMode("logged in") 

        } catch(error) {
            console.log(error.message)
            setAppMode("Error logging in")
        }
    }

    function handleLoginClickFromRegister({event}) {
        event.preventDefault()
        console.log("Clicked on log in after registering a new user")
        setAppMode('logged in')
    }

    function handleBackToLogin({event}) {
        setAppMode('logging in')
    }

    function handleRegisterClick({event}) {
        event.preventDefault()
        console.log("Clicked on register new user");
        
        setUsername('username')
        setEmail('email')
        setPassword('password')
        setAppMode("registering new user")
    }

    const handleCreateAccount = async ({event}) => {
        event.preventDefault()
        console.log('Clicked on create new account');

        try {
            console.log(username, password)
            const request_options = {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Headers": "*", // this will allow all CORS requests
                    "Access-Control-Allow-Methods": 'OPTIONS,POST,GET', // this states the allowed methods
                    "Content-Type": "application/json" // this shows the expected content type
                },
                body: JSON.stringify({'username': username, 'password': password})
            }
            console.log(request_options)
            const response =  await fetch(request_path + '/create_new_user', request_options)
            
            const response_data =  await response.json()
            console.log('Got back: ', response_data)

            if (!response.ok || response_data['result'] !== 'ok') {
                throw Error("Error creating new account")
            }

            console.log("Created new acount")

            cookieRemovingFunction()
		    console.log('Removed previously existing cookie')

            console.log("Trying to set the cookie ", response_data['token'])
            cookieSetter({user: response_data['token']})
            setAppMode('redirecting to login')

        } catch(error) {
            console.log(error.message)
            setAppMode("Error registering new user")
        }
    }

    return LoginRegisterUI
}