import React, { useState, useEffect, useLayoutEffect, useRef} from 'react';
import './App.css'
import { CookiesProvider, useCookies } from 'react-cookie';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import {LoginComponent} from './Login.jsx';


function App() {
  	const [appMode, setAppMode] = useState('logging in')
	const [cookie, setCookie, removeCookie] = useCookies(['user'])
  	const welcome_message = 
		{
		  message: "Hello, I'm your therapist! What would you like to speak about today?",
		  sentTime: "just now",
		  sender: "ChatGPT",
      direction: "incoming"
		}
  	const [messages, setMessages] = useState([welcome_message])
  	const [isTyping, setIsTyping] = useState(false)
	const msgListRef = useRef(null)

	msgListRef.current?.scrollToBottom('auto')

	const request_path = 'https://dannysapps.site:5000'


	function handleLoggingOut() {
		console.log('Logged out')
		removeCookie('user',{path:'/'});
		console.log('Removed cookie')
		setAppMode('logged out')
	}

	const handleSend = async (message) => {
	const newMessage = {
		message,
		direction: 'outgoing',
		sender: "user"
	};

	const newMessages = [...messages, newMessage];
	setMessages(newMessages);
	setIsTyping(true);

	try {
		const request_options = {
			method: 'POST',
			headers: {
			"Access-Control-Allow-Headers": "*", // this will allow all CORS requests
			"Access-Control-Allow-Methods": 'OPTIONS,POST,GET', // this states the allowed methods
			"Content-Type": "application/json" // this shows the expected content type
			},
			body: JSON.stringify({'token': {'user': cookie.user.user}, 'message': {'message': message, 'sender': 'user'}})
		}
		console.log("Sending message to the LLM, parameters: ", request_options)
		
		const response =  await fetch(request_path + '/process_messages', request_options)
		
		const response_data =  await response.json()
		console.log('Got back: ', response_data)

		if (!response.ok || response_data['result'] !== 'ok') {
			throw Error("Error receiving back answer")
		}

		setMessages([welcome_message, ...response_data['messages']])
		setIsTyping(false)

		} catch(error) {
			console.log(error.message)
		}
	};

	function cookieSetter(user) {
		let d = new Date()
		d.setTime(d.getTime() + (30*24*60*60*1000)) //30 days in milliseconds
		console.log("Expiration date: ", d)
		setCookie('user', user, {path: '/', expires: d})
	}

	function cookieAndMessageHistoryRemoval() {
		removeCookie('user',{path:'/'});
		setMessages([welcome_message])
	}

	useEffect(() => {
		const handleTokenAuthentication = async () => {
			console.log('Checking for cookies')

			if (cookie.user) {
				console.log('Found cookie, yummy! The cookie is: ', cookie.user.user)

				try {
					const request_options = {
						method: 'POST',
						headers: {
						"Access-Control-Allow-Headers": "*", // this will allow all CORS requests
						"Access-Control-Allow-Methods": 'OPTIONS,POST,GET', // this states the allowed methods
						"Content-Type": "application/json" // this shows the expected content type
						},
						body: JSON.stringify({'token': cookie.user.user})
					}
					
					const response =  await fetch(request_path + '/authenticate_token', request_options)

					const response_data =  await response.json()
					console.log('Got back: ', response_data)
		
					if (!response.ok || response_data['result'] !== 'ok') {
						throw Error("Error logging in")
					}
		
					console.log("Logged in ", response_data['username'])
					setAppMode('logged in')
		
				} catch(error) {
					console.error("Error logging in: " + error.message)
					//setAppMode("Error logging in")
				}

			} else {
				console.log('Found no cookies')
			}
		};
		
		document.title = "Digital therapist";
		handleTokenAuthentication();
	}, []);

	// Scroll to the bottom of the text box after all content has been rendered
	useLayoutEffect(() => {
		msgListRef.current?.scrollToBottom('auto')
	  }, [messages]);

	console.log('App mode at the top of the app: ', appMode)
	console.log("Cookie: ", cookie.user)

	let ChatUI = (
		<div className="container">
			<div className="box top-box">
				<button className="btn-hover-log-out color-3" onClick={handleLoggingOut}>Log out</button>
			</div>
			<div className="box bottom-box">
				<div className = "chat-container">
					<MainContainer style={{'max-height': '100%'}}>
						<ChatContainer>       
							<MessageList scrollBehavior="smooth" typingIndicator={isTyping ? <TypingIndicator content="Thinking" /> : null} ref={msgListRef}>
								{messages.map((message, i) => {
									// console.log(message)
									return <Message key={i} model={message} style={{textAlign:"left"}}/>
								})}
							</MessageList>
							<MessageInput attachButton='false' placeholder="Type message here" onSend={handleSend} autoFocus/>        
						</ChatContainer>
					</MainContainer>
				</div>
			</div>
		</div>
		)

	return (
		<CookiesProvider>
			<div className="App">
				{appMode !== 'logged in' && <LoginComponent appMode = {appMode} setAppMode = {setAppMode} cookie = {cookie} cookieSetter = {cookieSetter} cookieRemovingFunction={cookieAndMessageHistoryRemoval}/>}
				{appMode === 'logged in' && ChatUI}
			</div>
		</CookiesProvider>
	);

}

export default App
